import { useState, MouseEvent } from 'react';
import HideIcon from '@stageplus/icons/react/hide';
import ShowIcon from '@stageplus/icons/react/show';
import { FieldValues } from 'react-hook-form';
import { ButtonIconOnly } from 'src/components/buttons/icon-button';
import ControlledInputText, { ControlledInputTextProps } from 'src/components/forms/elements/controlled-input-text';
import useTranslate from 'src/hooks/use-translate';

export default function ControlledInputPassword<T extends FieldValues>({
  ...inputProps
}: Exclude<ControlledInputTextProps<T>, 'type'>) {
  const t = useTranslate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const ShowPasswordIcon = showPassword ? HideIcon : ShowIcon;

  return (
    <div className="relative">
      <ControlledInputText
        {...inputProps}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <ButtonIconOnly
            title={t(showPassword ? 'input__password_hide' : 'input__password_show')}
            icon={<ShowPasswordIcon className="opacity-55 mouse:group-hover:opacity-100" />}
            onClick={handleClick}
            type="button"
            active={showPassword}
            className="mr-1 size-8 rounded-lg"
          />
        }
      />
    </div>
  );
}
