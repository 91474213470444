import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { LoginLink } from 'src/components/auth-link';
import { TextButton } from 'src/components/buttons/text-button';
import ControlledInputPassword from 'src/components/forms/elements/controlled-input-password';
import ControlledInputText from 'src/components/forms/elements/controlled-input-text';
import LegalLinks from 'src/components/legal-links';
import Translate from 'src/components/translate';
import { useEmailFromQuery } from 'src/hooks/use-email-from-query';
import useErrorHandler from 'src/hooks/use-error-handler';
import useHideNavigation from 'src/hooks/use-hide-navigation';
import { SignupError, SignupInput, useSignup } from 'src/hooks/use-signup';
import { useTrackingParameters } from 'src/hooks/use-tracking-parameters';
import useTranslate from 'src/hooks/use-translate';
import { useImpressionTracking, trackEvent } from 'src/tracking';
import { useTrackingContext } from 'src/tracking/tracking-context';
import { getDeviceInfo } from 'src/utilities/device-info';
import { isEmail, isLikelyInvalidEmail } from 'src/utilities/validator';

type SignupFormProps = {
  /**
   * Callback function when user successfully signs up
   */
  onSubmitSuccess?: () => void;
  onChange?: () => void;
};

const legalTextLinkClasses = 'text-white text-opacity-100 underline';

const errorTypeWarning = 'warning';

/**
 * A form displayed when user is trying to create a new account.
 * The component can be displayed both on a static page or in a modal view.
 */
export default function SignupForm({ onSubmitSuccess, onChange }: SignupFormProps) {
  const t = useTranslate();
  const { control, handleSubmit, formState, setError, clearErrors, setValue, register } = useForm<SignupInput>();
  const trackingDisplayContext = useTrackingContext();
  const { affiliateInfo } = useTrackingParameters();
  const isLimitedNavigation = useHideNavigation();
  const { signup } = useSignup({ successNotification: !isLimitedNavigation });
  const handleError = useErrorHandler();
  // After an unsuccessful submit, the form is disabled until the user changes the username input unless it is simply a warning
  const canSubmit = formState.errors.username === undefined || formState.errors.username.type === errorTypeWarning;

  // when ?email=foo@bar.baz parameter is present, prefill the username input
  // with that value
  const emailFromQuery = useEmailFromQuery();
  useEffect(() => {
    if (emailFromQuery) {
      setValue('username', emailFromQuery);
    }
  }, [emailFromQuery, setValue]);

  // Track each time when the user sees the component
  const { impressionObserverRef } = useImpressionTracking({
    eventName: 'SignupDisplayed',
    method: 'Email',
    displayContext: trackingDisplayContext,
  });

  useEffect(() => {
    if (affiliateInfo) {
      setValue('affiliateGroup', affiliateInfo);
    }
  }, [affiliateInfo, setValue]);

  const onSubmit: Parameters<typeof handleSubmit>[0] = async (values: SignupInput) => {
    try {
      await signup(values);
      onSubmitSuccess?.();
    } catch (error) {
      if (error instanceof SignupError) {
        if (error.field === 'username' && error.validation === 'format') {
          setError(error.field, {
            message: t('validation__email_invalid'),
          });
        } else if (error.field === 'username' && error.validation === 'unique') {
          setError(error.field, {
            message: t('signup__form_error_user_already_signed'),
          });
        } else if (error.field === 'password' && error.validation === 'length') {
          setError(error.field, {
            message: t('signup__form_error_password_too_short'),
          });
        }
      }
    }
  };

  const onError: Parameters<typeof handleSubmit>[1] = (fieldErrors) => {
    trackEvent({
      eventName: 'SignupError',
      method: 'Email',
      errors: fieldErrors,
      displayContext: trackingDisplayContext,
    });
  };

  return (
    <div data-test="signup-form">
      <form
        noValidate
        ref={impressionObserverRef}
        onSubmit={handleSubmit(onSubmit, onError)}
        onChange={onChange}
        className="relative flex flex-col"
      >
        <div className="relative mb-4 flex flex-col space-y-4">
          <div className="mb-1 md:mb-2">
            <h1 className="dg-text-medium-2 mb-6 text-center lg:mb-4">{t('signup__form_title')}</h1>
            <p className="dg-text-regular-3 mb-2 text-center text-white text-opacity-70">{t('signup__form_cta')}</p>
            <p className="dg-text-regular-3 text-center text-white text-opacity-70">
              <Translate
                i18nKey="signup__signin"
                components={{
                  signin: (
                    <LoginLink className="text-white underline" trackingTextId="signup__login">
                      {t('signup__login')}
                    </LoginLink>
                  ),
                }}
              />
            </p>
          </div>
          <ControlledInputText
            label={t('signup__form_email')}
            control={control}
            name="username"
            id="signup__form_email"
            type="email"
            autoComplete="username"
            defaultValue=""
            rules={{
              required: t('signup__form_error_email_required'),
              validate: {
                isEmail: (value) => isEmail(value) || t('signup__form_error_email_wrong'),
              },
            }}
            onBlur={async (event) => {
              const email = event.target.value;
              // Only check the email on blur if it is in a valid format to avoid unnecessary API calls
              if (isEmail(email) && !formState.isSubmitting) {
                try {
                  // Check if the value is likely a valid email (e.g. a typo in the domain name is detected as invalid)
                  const isLikelyInvalid = await isLikelyInvalidEmail(email);
                  // Set the state to show a warning message or clear the warning message otherwise
                  if (isLikelyInvalid) {
                    setError('username', {
                      message: t('validation__email_likely_invalid'),
                      type: errorTypeWarning,
                    });
                  } else if (formState.errors.username?.type === errorTypeWarning) {
                    // Only reset the error if it was a warning, otherwise we might clear server-side validation error
                    clearErrors('username');
                  }
                } catch (error) {
                  if (error instanceof Error) {
                    handleError(error);
                  }
                }
              }
            }}
          />
          <ControlledInputPassword
            label={t('signup__form_password')}
            control={control}
            name="password"
            id="signup__form_password"
            autoComplete="new-password"
            defaultValue=""
            rules={{
              required: t('signup__form_error_password_required'),
              minLength: {
                value: 6,
                message: t('signup__form_error_password_too_short'),
              },
            }}
          />
          <input type="hidden" {...register('affiliateGroup')} />
          <input type="hidden" {...register('deviceInfo')} defaultValue={getDeviceInfo()} />
          <p className="dg-text-regular-4 dg-text-regular-6 mb-6 pb-10 text-white text-opacity-70 md:pb-2">
            <Translate
              i18nKey="signup__form_more_info"
              components={{
                1: <LegalLinks.SecurityDg target="_blank" className={legalTextLinkClasses} />,
                3: <LegalLinks.Notice target="_blank" className={legalTextLinkClasses} />,
                5: <LegalLinks.Terms target="_blank" className={legalTextLinkClasses} />,
              }}
            />
          </p>
        </div>
        <TextButton
          type="submit"
          data-test="button-user-signup-submit"
          loading={formState.isSubmitting}
          fullWidth
          disabled={!canSubmit}
        >
          {t('signup__form_button')}
        </TextButton>
      </form>
    </div>
  );
}
